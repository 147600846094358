import { MrModalDialog, Cookie } from '@mrhenry/wp--mr-interactive';

class MrAgeGateModal extends MrModalDialog {
	#clickHandler = ( e: MouseEvent ) => {
		if ( !e.target || !( e.target instanceof HTMLAnchorElement ) ) {
			return;
		}

		let passesAllChecks = true;
		this.querySelectorAll( '[mr-age-gate-condition="required"]' ).forEach( ( el ) => {
			if ( !( el instanceof HTMLInputElement ) ) {
				return;
			}

			if ( el.checked ) {
				return;
			}

			passesAllChecks = false;
		} );

		if ( !passesAllChecks ) {
			e.preventDefault();
			e.stopPropagation();

			return;
		}


		const region = e.target.getAttribute( 'mr-age-gate-region' );
		if ( !region ) {
			return;
		}

		this.grantEntrance( region );

		if ( region === this.getAttribute( 'mr-age-gate-region' ) ) {
			// Skip navigation
			e.preventDefault();
			e.stopPropagation();
			super.updateState( 'close' );

			return;
		}
	};

	override connectedCallback() {
		super.connectedCallback();

		this.addEventListener( 'click', this.#clickHandler );

		requestAnimationFrame( () => {
			const region = this.getAttribute( 'mr-age-gate-region' );

			if ( region && !this.passedGateInRegion( region ) ) {
				this.updateState( 'open' );

				return;
			}

			this.setAttribute( 'passed', '' );
			this.querySelectorAll( '[mr-age-gate-condition="required"]' ).forEach( ( el ) => {
				if ( !( el instanceof HTMLInputElement ) ) {
					return;
				}

				el.checked = true;
			} );
		} );
	}

	override disconnectedCallback() {
		this.removeEventListener( 'click', this.#clickHandler );
	}

	override async updateState( directive: string ): Promise<void> {
		const region = this.getAttribute( 'mr-age-gate-region' );
		if ( region && this.passedGateInRegion( region ) ) {
			super.updateState( directive );

			return;
		}

		if ( 'close' === directive ) {
			return;
		}

		super.updateState( directive );
	}

	passedGateInRegion( region: string ): boolean {
		try {
			if ( /bot|google|baidu|bing|msn|duckduckbot|teoma|slurp|yandex/i.test( navigator.userAgent ) ) {
				return true;
			}
		} catch ( e ) {
			console.warn( e );
			/* noop */
		}

		const match = document.cookie.match( new RegExp( '(^| )vedett_gate=([^;]+)' ) );
		let records: Array<{ key: string, value: string }> = [];
		if ( match && match[2] ) {
			records = Cookie.unpackRecords( match[2] );
		}

		records = records.filter( ( record ) => {
			return record.key === region && '1' === record.value;
		} );

		return 0 < records.length;
	}

	grantEntrance( region: string ): void {
		const match = document.cookie.match( new RegExp( '(^| )vedett_gate=([^;]+)' ) );
		let records: Array<{ key: string, value: string }> = [];
		if ( match && match[2] ) {
			records = Cookie.unpackRecords( match[2] );
		}

		records = records.filter( ( record ) => {
			return record.key !== region;
		} );

		records.push( {
			key: region,
			value: '1',
		} );

		const cookieExpiration = new Date();
		cookieExpiration.setTime( cookieExpiration.getTime() + 90 * 24 * 60 * 60 * 1000 );

		document.cookie = `vedett_gate=${Cookie.packRecords( records )}; path=/; secure; samesite=lax; expires=` + cookieExpiration.toUTCString();
	}
}

customElements.define( 'mr-age-gate-modal', MrAgeGateModal );
