import { MrModalDialog, PrivacyControls } from '@mrhenry/wp--mr-interactive';

class MrYouTubeEmbedNotice extends MrModalDialog {
	override async updateState( state: string ): Promise<void> {
		if ( 'close-and-accept' === state ) {
			document.querySelectorAll( '[mr-prv-embedder-blocked="yt"]' ).forEach( ( el ) => {
				el.removeAttribute( 'mr-prv-embedder-blocked' );
				el.removeAttribute( 'tabindex' );
			} );

			PrivacyControls.enableEmbedder( 'yt' );

			return super.updateState( 'close' );
		}

		if ( 'close-and-reject' === state ) {
			return super.updateState( 'close' );
		}

		return super.updateState( state );
	}
}

customElements.define( 'mr-youtube-embed-notice', MrYouTubeEmbedNotice );
