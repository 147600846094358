class SttupidStrikeThrough extends HTMLElement {
	// MARK : properties
	#names: Array<string> = [
		'Alexis',
		'Bioni',
		'Cameron',
		'Corey',
		'Elise',
		'Felix',
		'Kresse',
		'Louis',
		'Matilde',
		'Matthias',
		'Melinda',
		'Patrick',
		'Peter',
		'Tom',
		'Vincent',
		'Warren',

		// You has multiple entries, so it has more chance of showing up.
		'You',
		'You',
		'You',
		'You',
		'You',
		'You',
		'You',
	];

	#mouseenterHandler = (): void => {
		this.setRandomText();
	};

	// MARK : lifecycle

	connectedCallback() {
		requestAnimationFrame( () => {
			const forEl = this.getForEl();
			if ( !forEl ) {
				return; // no forEl, so can't do fancy stuff
			}

			forEl.addEventListener( 'mouseenter', this.#mouseenterHandler );
		} );
	}

	disconnectedCallback() {
		const forEl = this.getForEl();
		if ( !forEl ) {
			return;
		}

		forEl.removeEventListener( 'mouseenter', this.#mouseenterHandler );
	}

	// MARK : methods

	setRandomText() {
		requestAnimationFrame( () => {
			this.setAttribute( 'data-strike-through-text', this.#names[Math.floor( Math.random() * this.#names.length )] );
		} );
	}

	getForEl(): HTMLElement|null {
		const forElId = this.getAttribute( 'for' );
		if ( !forElId ) {
			return null;
		}

		return document.getElementById( forElId );
	}
}

customElements.define( 'sttupid-strike-through', SttupidStrikeThrough );
