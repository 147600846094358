class MrScrollToTop extends HTMLElement {
	// MARK : properties

	#resizeHandler = (): void => {
		requestAnimationFrame( () => {
			this.resetStartPosition();
			this.setStartPosition();
		} );
	};

	// MARK : lifecycle

	connectedCallback() {
		requestAnimationFrame( () => {
			window.addEventListener( 'resize', this.#resizeHandler );
			this.setStartPosition();
		} );
	}

	disconnectedCallback() {
		this.resetStartPosition();
		window.removeEventListener( 'resize', this.#resizeHandler );
	}

	// MARK : methods

	setStartPosition() {
		if ( window.matchMedia( '(max-width: 767px)' ).matches ) {
			return; // on mobile fixed is set with css
		}

		const rect = this.getBoundingClientRect();
		this.style.left = `${rect.left + document.documentElement.scrollLeft}px`;
		this.style.top = `${rect.top + document.documentElement.scrollTop}px`;
		this.style.position = 'fixed';
	}

	resetStartPosition() {
		this.removeAttribute( 'style' );
	}
}

customElements.define( 'mr-scroll-to-top', MrScrollToTop );
