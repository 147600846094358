import { deviceOrientationHandler } from '../helpers/device-orientation-handler';

class MrBeerTeaser extends HTMLElement {
	// MARK : properties

	#startPosition: number|null = 0;

	#previousPosition: number|null = 0;

	#bottlesContainer: HTMLElement|null = null;

	#bottles: Array<HTMLElement>|null = [];

	#mouseenterHandler = ( e: MouseEvent ): void => {
		this.#startPosition = e.clientX;
		this.#previousPosition = this.#startPosition;
		this.addEventListener( 'mousemove', this.#mousemoveHandler );
	};

	#mousemoveHandler = ( e: MouseEvent ): void => {
		if ( null === this.#startPosition ) {
			return;
		}

		const delta = ( this.#previousPosition || 0 ) - e.clientX;
		const distanceMoved = e.clientX - this.#startPosition;

		this.#previousPosition = e.clientX;

		this.moveBottles( delta, distanceMoved );
	};

	#mouseleaveHandler = (): void => {
		this.removeEventListener( 'mousemove', this.#mousemoveHandler );

		this.resetBottles();
	};

	// MARK : lifecycle

	connectedCallback() {
		if ( !this.isActive() ) {
			return;
		}

		requestAnimationFrame( () => {
			this.#bottlesContainer = this.querySelector( '.js-beer-teaser-bottles-container' );

			if ( this.#bottlesContainer ) {
				this.#bottles = Array.from( this.#bottlesContainer.querySelectorAll( '.js-beer-teaser-bottle' ) );
			}

			if ( this.hasHover() ) {
				this.addEventListener( 'mouseenter', this.#mouseenterHandler );
				this.addEventListener( 'mouseleave', this.#mouseleaveHandler );
			}

			if ( deviceOrientationHandler.hasDeviceOrientation() ) {
				deviceOrientationHandler.addListener( this.deviceOrientationHandler );
			}
		} );
	}

	disconnectedCallback() {
		this.removeEventListener( 'mouseenter', this.#mouseenterHandler );
		this.removeEventListener( 'mouseleave', this.#mouseleaveHandler );

		deviceOrientationHandler.removeListener( this.deviceOrientationHandler );
	}

	// MARK : methods

	isActive(): boolean {
		// First of all, we want to know if the user actually wants to see this.
		if ( window.matchMedia( '(prefers-reduced-motion: reduce)' ).matches ) {
			return false;
		}

		// Then we're gonna check if the user can see this.
		if ( !this.hasHover() && !deviceOrientationHandler.hasDeviceOrientation() ) {
			return false;
		}

		return true;
	}

	hasHover(): boolean {
		if ( !window.matchMedia( '(hover: hover)' ).matches ) {
			return false;
		}

		return true;
	}

	moveBottles( delta: number, distanceMoved: number ) {
		requestAnimationFrame( () => {
			if ( null === this.#bottlesContainer ) {
				return;
			}

			// move bottles container
			this.#bottlesContainer.style.transform = `translate3d(-50%, -50%, 0) translateX(${distanceMoved * -0.075}px)`;

			if ( null === this.#bottles ) {
				return;
			}

			if ( !this.#bottles.length ) {
				return;
			}

			// slightly rotate bottles
			if ( 0 < delta ) {
				this.#bottles.forEach( ( bottle ) => {
					bottle.style.transform = 'rotate(5deg)';
				} );
			}

			if ( 0 > delta ) {
				this.#bottles.forEach( ( bottle ) => {
					bottle.style.transform = 'rotate(-5deg)';
				} );
			}
		} );
	}

	resetBottles( ) {
		requestAnimationFrame( () => {
			if ( null === this.#bottlesContainer ) {
				return;
			}

			this.#bottlesContainer.style.transform = 'translate3d(-50%, -50%, 0)';

			if ( null === this.#bottles ) {
				return;
			}

			if ( !this.#bottles.length ) {
				return;
			}

			this.#bottles.forEach( ( bottle ) => {
				bottle.style.transform = 'rotate(0)';
			} );
		} );
	}

	deviceOrientationHandler = ( e: DeviceOrientationEvent ): void => {
		if ( null === e.alpha || null === e.beta || null === e.gamma ) {
			return;
		}

		if ( null === this.#bottlesContainer ) {
			return;
		}

		if ( null === this.#bottles ) {
			return;
		}

		if ( !this.#bottles.length ) {
			return;
		}

		// move horizontally
		this.#bottlesContainer.style.transition = 'none';
		if ( 'orientation' in window && 180 === window.orientation ) {
			// you're holding it wrong
			this.#bottlesContainer.style.transform = `translate3d(-50%, -50%, 0) translateX(${e.gamma / 90 * -1 * 150}px)`;
		} else if ( 'orientation' in window && 90 === window.orientation ) {
			// right side up
			this.#bottlesContainer.style.transform = `translate3d(-50%, -50%, 0) translateX(${e.beta / 90 * 150}px)`;
		} else if ( 'orientation' in window && -90 === window.orientation ) {
			// left side up
			this.#bottlesContainer.style.transform = `translate3d(-50%, -50%, 0) translateX(${e.beta / 90 * -1 * 150}px)`;
		} else {
			// regular portrait
			this.#bottlesContainer.style.transform = `translate3d(-50%, -50%, 0) translateX(${e.gamma / 90 * 150}px)`;
		}

		// move angle
		let deviceAngle = Math.min( e.alpha, 90 );
		if ( 180 < e.alpha ) {
			deviceAngle = Math.max( e.alpha, 270 ) - 360;
		}

		const newAngle = deviceAngle / 90 * 40;
		this.#bottles.forEach( ( bottle ) => {
			bottle.style.transition = 'none';
			bottle.style.transform = `rotate3d(0,0,1,${newAngle}deg)`;
		} );
	};
}

customElements.define( 'mr-beer-teaser', MrBeerTeaser );
