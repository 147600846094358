import { deviceOrientationHandler } from '../helpers/device-orientation-handler';

class MrRequestDeviceOrientation extends HTMLElement {
	// MARK : properties

	#clickHandler = (): void => {
		deviceOrientationHandler.requestPermission().then( ( permissionState ) => {
			if ( permissionState ) {
				this.style.display = 'none';
			}
		} );
	};

	// MARK : lifecycle

	connectedCallback() {
		this.shouldDisplay().then( ( display ) => {
			if ( !display ) {
				if ( this.parentNode ) {
					this.parentNode.removeChild( this );
				}

				return;
			}

			this.style.display = 'block';

			this.addEventListener( 'click', this.#clickHandler );
		} );
	}

	disconnectedCallback() {
		this.style.display = 'none';
		this.removeEventListener( 'click', this.#clickHandler );
	}

	// MARK : methods

	shouldDisplay(): Promise<boolean> {
		// First of all, we want to know if the user actually wants to see this.
		if ( window.matchMedia( '(prefers-reduced-motion: reduce)' ).matches ) {
			return Promise.resolve( false );
		}

		// Users that can hover will only have the hover animation
		if ( window.matchMedia( '(hover: hover)' ).matches ) {
			return Promise.resolve( false );
		}

		// Then we're gonna check if the user CAN see this.
		if ( !deviceOrientationHandler.hasDeviceOrientation() ) {
			return Promise.resolve( false );
		}

		return deviceOrientationHandler.needsPermission();
	}
}

customElements.define( 'mr-request-device-orientation', MrRequestDeviceOrientation );
