/*
 * Device Orientation Handler
 *
 * This is a singleton which only handles the DeviceOrientation events.
 * You can import and use it to craft components responsive to the DeviceOrientationEvent
 */

class DeviceOrientationHandler {
	// MARK : properties

	#listeners: Map<( e: DeviceOrientationEvent ) => void, null> = new Map();

	#handler_lock: number | null = null;

	#lastOrientation: DeviceOrientationEvent | null = null;

	#deviceOrientationHandler = ( e: DeviceOrientationEvent ): void => {
		this.#lastOrientation = e;

		if ( this.#handler_lock ) {
			return;
		}

		this.#handler_lock = ( new Date() ).valueOf();
		requestAnimationFrame( () => {
			this.#handler_lock = null;

			this.#listeners.forEach( ( _, handler ) => {
				if ( !this.#lastOrientation ) {
					return;
				}

				handler( this.#lastOrientation );
			} );
		} );
	};

	// MARK : methods

	hasDeviceOrientation(): boolean {
		if ( !( 'DeviceOrientationEvent' in window ) ) {
			return false;
		}

		return true;
	}

	requestPermission(): Promise<boolean> {
		const deviceOrientationEventWithPermissions = getDeviceOrientationEventWithPermissions();
		if ( !deviceOrientationEventWithPermissions ) {
			return Promise.resolve( true );
		}

		return deviceOrientationEventWithPermissions.requestPermission().then( ( state ) => {
			if ( 'granted' === state ) {
				return true;
			}

			return false;
		} ).catch( ( err ) => {
			console.warn( err );

			return false;
		} );
	}

	needsPermission(): Promise<boolean> {
		if ( !this.hasDeviceOrientation() ) {
			return Promise.resolve( false );
		}

		const deviceOrientationEventWithPermissions = getDeviceOrientationEventWithPermissions();
		if ( !deviceOrientationEventWithPermissions ) {
			return Promise.resolve( true );
		}

		const fallback: Promise<boolean> = new Promise( ( resolve ) => {
			setTimeout( () => {
				resolve( true );
			}, 2000 );
		} );

		const firstEvent: Promise<boolean> = new Promise( ( resolve ) => {
			window.addEventListener( 'deviceorientation', () => {
				resolve( false );
			}, {
				once: true,
			} );
		} );

		const forceRequest: Promise<boolean> = new Promise( ( resolve ) => {
			return deviceOrientationEventWithPermissions.requestPermission().then( ( state ) => {
				if ( 'granted' === state ) {
					resolve( false );

					return;
				}

				resolve( true );

			} ).catch( ( err ) => {
				if ( 'NotAllowedError' === err.name ) {
					resolve( true );
				}
			} );
		} );

		return Promise.race( [
			fallback,
			firstEvent,
			forceRequest,
		] );
	}

	listenToDeviceOrientation(): void {
		window.addEventListener( 'deviceorientation', this.#deviceOrientationHandler );
	}

	addListener( handler: ( e: DeviceOrientationEvent ) => void ) {
		if ( 0 === this.#listeners.size && this.hasDeviceOrientation() ) {
			// first registration.
			// use this to start listening so we only init when needed.
			this.listenToDeviceOrientation();
		}

		this.#listeners.set( handler, null );
	}

	removeListener( handler: ( e: DeviceOrientationEvent ) => void ) {
		this.#listeners.delete( handler );
	}
}
export const deviceOrientationHandler = new DeviceOrientationHandler();

interface DeviceOrientationEventWithPermissions extends DeviceOrientationEvent {
	requestPermission() : Promise<string>
}

interface MaybeDeviceOrientationEventWithPermissions extends DeviceOrientationEvent {
	requestPermission?() : Promise<string>
}

function getDeviceOrientationEventWithPermissions(): DeviceOrientationEventWithPermissions|null {
	if ( !( 'DeviceOrientationEvent' in window ) ) {
		return null;
	}

	if ( !( 'requestPermission' in DeviceOrientationEvent ) ) {
		return null;
	}

	const maybe = ( ( DeviceOrientationEvent as unknown ) as MaybeDeviceOrientationEventWithPermissions );
	if ( 'function' !== typeof maybe.requestPermission ) {
		return null;
	}

	return ( ( DeviceOrientationEvent as unknown ) as DeviceOrientationEventWithPermissions );
}
