import { MrDialog, PrivacyControls, Cookie } from '@mrhenry/wp--mr-interactive';

class MrPrivacyDialog extends MrDialog {

	#controlValueChangeHandler = ( e: Event ) => {
		if ( e.target && ( e.target instanceof HTMLInputElement ) ) {
			const input = e.target;
			if ( input.hasAttribute( 'mr-prv-gtm-control' ) ) {
				this.updateState( 'close-with-implicit-accept' );

				return;
			}

			if ( input.hasAttribute( 'mr-prv-embedder-control' ) ) {
				this.updateState( 'close-with-implicit-accept' );

				return;
			}
		}
	};

	override connectedCallback() {
		super.connectedCallback();


		requestAnimationFrame( () => {
			if ( !this.hasAccepted() ) {
				this.updateState( 'open' );

				return;
			}
		} );

		window.addEventListener( 'change', this.#controlValueChangeHandler );
	}

	override disconnectedCallback() {
		super.disconnectedCallback();

		window.removeEventListener( 'change', this.#controlValueChangeHandler );
	}

	override async updateState( directive: string ): Promise<void> {
		if ( 'close-and-accept' === directive ) {
			this.accept();
			this.applyDefaults();

			super.updateState( 'close' );

			return;
		}

		if ( 'close-with-implicit-accept' === directive ) {
			this.accept();

			super.updateState( 'close' );

			return;
		}

		super.updateState( directive );
	}

	hasAccepted(): boolean {
		const match = document.cookie.match( new RegExp( '(^| )vedett_privacy_acceptance=([^;]+)' ) );
		let records: Array<{ key: string, value: string }> = [];
		if ( match && match[2] ) {
			records = Cookie.unpackRecords( match[2] );
		}

		const accepted = ( 0 < records.filter( ( record ) => {
			return 'ac' === record.key && '1' === record.value;
		} ).length );

		if ( !accepted ) {
			return false;
		}

		const lastAccepted = records.filter( ( record ) => {
			return 'lm' === record.key;
		} ).map( ( record ) => {
			return record.value;
		} )[0] || false;

		if ( !lastAccepted ) {
			return false;
		}

		const lastAcceptedN = parseInt( lastAccepted, 10 );
		if ( Number.isNaN( lastAcceptedN ) ) {
			return false;
		}

		const lastModified = Date.parse( this.getAttribute( 'last-modified' ) || '' ).valueOf();
		if ( lastModified <= lastAcceptedN ) {
			return true;
		}

		return false;
	}

	accept(): void {
		const match = document.cookie.match( new RegExp( '(^| )vedett_privacy_acceptance=([^;]+)' ) );
		let records: Array<{ key: string, value: string }> = [];
		if ( match && match[2] ) {
			records = Cookie.unpackRecords( match[2] );
		}

		records = records.filter( ( record ) => {
			return 'lm' !== record.key && 'ac' !== record.key;
		} );

		const lastModified = Date.parse( this.getAttribute( 'last-modified' ) || '' ).valueOf();
		if ( !Number.isNaN( lastModified ) ) {
			records.push( {
				key: 'lm',
				value: lastModified.toString(),
			} );
		}

		records.push( {
			key: 'ac',
			value: '1',
		} );

		const cookieExpiration = new Date();
		cookieExpiration.setTime( cookieExpiration.getTime() + 90 * 24 * 60 * 60 * 1000 );

		document.cookie = `vedett_privacy_acceptance=${Cookie.packRecords( records )}; path=/; secure; samesite=lax; expires=` + cookieExpiration.toUTCString();
	}

	applyDefaults(): void {
		// This is hard coded here for now.
		// Might be better if we can pull this from ACF
		// see : wp-content/themes/vedett/blocks/privacy/privacy-control/privacy-control.php
		PrivacyControls.enableInGTM( 'fp' ); // Facebook pixel
		PrivacyControls.enableInGTM( 'ga' ); // Google Analytics
		PrivacyControls.enableInGTM( 'gr' ); // Google Remarketing

		// Youtube embeds.
		PrivacyControls.enableEmbedder( 'yt' ); // Youtube embeds
		// Unblock Youtube content.
		document.querySelectorAll( '[mr-prv-embedder-blocked="yt"]' ).forEach( ( el ) => {
			el.removeAttribute( 'tabindex' );
			el.removeAttribute( 'mr-prv-embedder-blocked' );
		} );

		if ( document.querySelector( '[mr-prv-gtm-control], [mr-prv-embedders-control]' ) ) {
			location.reload();
		}
	}

	override firstFocusableElement(): HTMLElement | void {
		// override super.
		// keep focus on this currently active element.
		if ( document.activeElement && ( document.activeElement instanceof HTMLElement ) ) {
			return document.activeElement;
		}

		return super.firstFocusableElement();
	}
}

customElements.define( 'mr-privacy-dialog', MrPrivacyDialog );
