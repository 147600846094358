class MrHoverLabel extends HTMLElement {
	// MARK : properties

	#x: number|null = null;

	#y: number | null = null;

	#mouseoverHandler = ( e: MouseEvent ): void => {
		const forEl = this.getForEl();
		if ( !forEl ) {
			return;
		}

		this.moveLabel( e );
		this.style.display = 'block';

		forEl.addEventListener( 'mousemove', this.#mousemoveHandler );
	};

	#mousemoveHandler = ( e: MouseEvent ): void => {
		this.moveLabel( e );
	};

	#mouseoutHandler = (): void => {
		const forEl = this.getForEl();
		if ( !forEl ) {
			return;
		}

		this.style.display = 'none';

		forEl.removeEventListener( 'mousemove', this.#mousemoveHandler );
	};

	// MARK : lifecycle

	connectedCallback() {
		requestAnimationFrame( () => {
			const forEl = this.getForEl();
			if ( !forEl ) {
				return; // no forEl, so can't do fancy stuff
			}

			// Note : It is possible that someone starts on a small screen and then resizes.
			// The title will still be set in that case which can cause a double tooltip.
			// This is fine.

			if ( this.isActive() ) {
				forEl.removeAttribute( 'title' );
			}

			forEl.addEventListener( 'mouseover', this.#mouseoverHandler );
			forEl.addEventListener( 'mouseout', this.#mouseoutHandler );
		} );
	}

	disconnectedCallback() {
		const forEl = this.getForEl();
		if ( !forEl ) {
			return;
		}

		// set back the `title` attribute
		if ( this.isActive() ) {
			forEl.setAttribute( 'title', this.textContent ?? '' );
		}

		forEl.removeEventListener( 'mouseover', this.#mouseoverHandler );
		forEl.removeEventListener( 'mousemove', this.#mousemoveHandler );
		forEl.removeEventListener( 'mouseout', this.#mouseoutHandler );
	}

	// MARK : methods

	isActive(): boolean {
		// First of all, we want to know if the user actually wants to see this.
		if ( window.matchMedia( '(prefers-reduced-motion: reduce)' ).matches ) {
			return false;
		}

		// Then we're gonna check if the user can see this.
		if ( !window.matchMedia( '(hover: hover)' ).matches ) {
			return false;
		}

		return true;
	}

	moveLabel( e: MouseEvent ) {
		this.#x = e.clientX;
		this.#y = e.clientY;

		requestAnimationFrame( () => {
			if ( null === this.#x || null === this.#y ) {
				return;
			}

			this.style.left = `${this.#x}px`;
			this.style.top = `${this.#y}px`;
		} );
	}

	getForEl(): HTMLElement|null {
		const forElId = this.getAttribute( 'for' );
		if ( !forElId ) {
			return null;
		}

		return document.getElementById( forElId );
	}
}

customElements.define( 'mr-hover-label', MrHoverLabel );
