class MrRandomListSwapper extends HTMLElement {
	// MARK : properties

	#ticker = 0;

	#tick = (): void => {
		const labels = this.querySelectorAll( '[swappable]' );
		if ( 22 >= labels.length ) {
			// No remaining items to swap with.
			window.clearInterval( this.#ticker );

			return;
		}

		this.swapSomeLabels( labels );
	};

	// MARK : lifecycle

	connectedCallback() {
		// don't do anything if it shouldn't be showed in the first place
		if ( !this.isActive() ) {
			return;
		}

		this.#ticker = window.setInterval( () => {
			this.#tick();
		}, 384 );
	}

	disconnectedCallback() {
		window.clearInterval( this.#ticker );
	}

	// MARK : methods

	isActive(): boolean {
		// First of all, we want to know if the user actually wants to see this.
		if ( window.matchMedia( '(prefers-reduced-motion: reduce)' ).matches ) {
			return false;
		}

		return true;
	}

	swapSomeLabels( labels : NodeList ) {
		const min = 0;
		let split = 19;
		if ( window.matchMedia( '(min-width: 768px)' ).matches ) {
			split = 21;
		}

		const randomVisibleIndex = this.getRandomNumber( min, split );
		const nextInvisibleIndex = split + 1;

		const aParent = labels[randomVisibleIndex].parentNode;
		if ( !aParent ) {
			return;
		}

		const swapOutLabel = labels[randomVisibleIndex];
		const swapInLabel = labels[nextInvisibleIndex];

		aParent.insertBefore( swapInLabel, swapOutLabel );
		aParent.appendChild( swapOutLabel );

		if ( !( swapInLabel instanceof HTMLElement ) ) {
			return;
		}

		swapInLabel.setAttribute( 'swapped-in', 'true' );
		swapInLabel.addEventListener( 'animationend', () => {
			swapInLabel.removeAttribute( 'swapped-in' );
		} );
	}

	getRandomNumber( min: number, max: number ): number {
		return Math.floor( Math.random() * ( max - min + 1 ) ) + min;
	}
}

customElements.define( 'mr-random-list-swapper', MrRandomListSwapper );
